import { env } from 'utils/env'

const root = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
const AUTH = {
  CALLBACK: '/authentication/callback',
  SILENT_CALLBACK: '/authentication/silent_callback'
}

const getAuthenticationConfiguration = () => {
  const config = {
    client_id: env.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: `${root}${AUTH.CALLBACK}`,
    silent_redirect_uri: `${root}${AUTH.SILENT_CALLBACK}`,
    scope: 'openid profile offline_access ' + env.REACT_APP_IDENTITY_SCOPE,
    authority: env.REACT_APP_IDENTITY_AUTHORITY,
    register_uri: `${env.REACT_APP_IDENTITY_AUTHORITY}/${env.REACT_APP_REGISTER_REDIRECT_URL}?returnUrl=${root}/register&clientId=${env.REACT_APP_IDENTITY_CLIENT_ID}`,
    service_worker_only: false
  }
  if (window.location.pathname === env.REACT_APP_IDENTITY_TESTUSERS_TRIGGER_QUERYSTRING) {
    config.extras = {
      acr_values: 'idp:local'
    }
  }
  return config
}

export default getAuthenticationConfiguration
